var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{directives:[{name:"ripple",rawName:"v-ripple",value:(!_vm.readOnly),expression:"!readOnly"}],staticClass:"lay-root",class:[
        `thema-${_vm.thema}`,
        _vm.readOnly? `read-only` : ``
    ],style:({
        marginTop: _vm.marginTop,
        marginBottom: _vm.marginBottom,
        marginLeft: _vm.marginLeft,
        marginRight: _vm.marginRight,
        padding: _vm.paddingSel,
        width: typeof _vm.width == 'number' ?  _vm.width : _vm.width,
        maxWidth: typeof _vm.width == 'number' ?  _vm.width : _vm.width,
        height: _vm.height,
        borderRadius: _vm.borderRadius
    }),attrs:{"align-center":"","justify-center":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.onClick && !_vm.readOnly ? _vm.onClick() : ''}}},[_vm._t("inside-left"),_c('span',{staticClass:"btn-txt",style:({ fontSize: _vm.fontSize, fontWeight: _vm.fontWeight})},[_vm._v(_vm._s(_vm.txtBtn))]),_vm._t("inside-right")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }